import React from 'react'
import AppHead from '@/Layouts/app-head'

interface ErrorPageProps {
    status: number
    msg: any
}

const ErrorPage: React.FC<ErrorPageProps> = ({ status, msg }) => {
    console.error(msg)
    const titles: { [key: number]: string } = {
        503: '503: Service Unavailable',
        500: '500: Server Error',
        404: '404: Page Not Found',
        403: '403: Forbidden'
    }

    const descriptions: { [key: number]: string } = {
        503: 'Sorry, we are doing some maintenance. Please check back soon.',
        500: 'Whoops, something went wrong on our servers.',
        404: 'Sorry, the page you are looking for could not be found.',
        403: 'Sorry, you are forbidden from accessing this page.'
    }

    const title =
        titles[status] || `${status ? status : 'Error'}: Something went wrong`
    const description =
        descriptions[status] ||
        msg ||
        'An unexpected error has occurred. Please try again later.'

    return (
        <div className='py-20 spacing-component-max-width'>
            <AppHead title={title} />

            <div
                role='alert'
                className='rounded border-s-4 border-danger-500 bg-danger-100 p-4'
            >
                <strong className='block font-medium text-danger-800'>
                    {title}
                </strong>

                <p className='mt-2 text-sm text-danger-700'>{description}</p>
            </div>
        </div>
    )
}

export default ErrorPage
